import { Controller } from "@hotwired/stimulus"
import Cropper from "cropperjs"
import { Turbo } from "@hotwired/turbo-rails"
import heic2any from "heic2any";
import "cropperjs/dist/cropper.css"

export default class extends Controller {
  static targets = [ "input", "preview", "form", "submit", "rotateRight", "rotateLeft", "cropper" ]

  connect() {
    this.reader = new FileReader()
    this.reader.onloadend = () => {
      this.previewTarget.src = this.reader.result
      this.cropper = new Cropper(this.previewTarget, { autoCrop: true, autoCropArea: 0.9 })
      this.updateVisibilities();
    }
  }

  select(event) {
    if (event.target.files.length) {
      const file = event.target.files[0];
      if (file.type === "image/heic") {
        heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.8
        })
        .then((jpegBlob) => {
          this.loadImage(jpegBlob);
        })
        .catch(() => {
          console.error("Failed to convert HEIC image");
        });
      } else {
        this.loadImage(file);
      }
    }
  }

  loadImage(file) {
    this.reader = new FileReader();
    this.reader.onloadend = () => {
      this.previewTarget.src = this.reader.result;
      if (this.cropper) {
        this.cropper.replace(this.reader.result);
      } else {
        this.cropper = new Cropper(this.previewTarget, { autoCrop: true, autoCropArea: 0.9 });
      }
      this.updateVisibilities();
    };
    this.reader.readAsDataURL(file);
  }

  submit_form(event) {
    event.preventDefault()
    this.submitTarget.disabled = true
    this.submitTarget.value = "Trwa przesyłanie..."

    let formData = new FormData(this.formTarget)

    if (this.cropper) {
      // If an image is selected, append the cropped image
      this.cropper.getCroppedCanvas().toBlob(blob => {
        formData.append('exercise[image]', blob, 'image.png');
        this.sendFormData(formData);
      })
    } else {
      // If no image is selected, send the form without the image
      this.sendFormData(formData);
    }
  }

  sendFormData(formData) {
    fetch(this.formTarget.action, {
      method: this.formTarget.method,
      body: formData,
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    }).then(response => {
      if (response.redirected) {
        Turbo.visit(response.url, { action: "replace" })
      } else {
        response.text().then(html => Turbo.renderStreamMessage(html))
      }
    })
  }

  updateVisibilities() {
    if (this.cropper) {
      this.cropperTarget.classList.remove('hidden');
      this.inputTarget.classList.add('hidden');
    } else {
      this.cropperTarget.classList.add('hidden');
      this.inputTarget.classList.remove('hidden');
    }
  }

  rotateRight() {
    if (this.cropper) {
      this.cropper.rotate(90);
    }
  }

  rotateLeft() {
    if (this.cropper) {
      this.cropper.rotate(-90);
    }
  }
}
