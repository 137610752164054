import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["number"];

  connect() {
    this.setVars();
    this.init();
  }

  setVars() {
    this.observerOptions = {
      root: null,
      rootMargin: "0px 0px",
      threshold: 0
    };
    // Bind the observer callback to the current context of 'this'
    this.observer = new IntersectionObserver(this.handleIntersection.bind(this), this.observerOptions);
  }

  init() {
    this.numberTargets.forEach((el) => {
      // Initialize a flag to check if the element has been animated
      el.dataset.hasAnimated = "false";
      this.observer.observe(el);
    });
  }

  handleIntersection(entries) {
    entries.forEach((entry) => {
      const target = entry.target;
      if (entry.isIntersecting && target.dataset.hasAnimated === "false") {
        const end = parseFloat(target.dataset.countupNumber.replace(/,/g, ""));
        const start = parseFloat(target.dataset.countupStart) || 0;
        const decimals = this.countDecimals(end);
        this.iterateValue(target, start, end, decimals);
        // Set the flag indicating that the element has been animated
        target.dataset.hasAnimated = "true";
      }
    });
  }

  iterateValue(el, start, end, decimals) {
    const duration = 2500;
    let startTimestamp = null;

    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const easedProgress = this.easeOutQuint(progress);
      let currentNumber = start + easedProgress * (end - start);
      el.textContent = this.formatNumber(currentNumber, decimals);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }

  // Remaining helpers methods (easeOutQuint, countDecimals, formatNumber) copied from the original class
  easeOutQuint(x) {
    return 1 - Math.pow(1 - x, 5);
  }

  countDecimals(val) {
    if (Math.floor(val) === val) return 0;
    return val.toString().split(".")[1].length || 0;
  }

  formatNumber(val, decimals) {
    return val.toLocaleString("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    });
  }
}

// The HTML should be like this:
// <div data-controller="count-up">
//   <span data-count-up-target="number" data-countup-start="50" data-countup-number="1000">1000</span>
// </div>
